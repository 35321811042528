<!--
 * @Author: your name
 * @Date: 2021-03-02 13:58:36
 * @LastEditTime: 2024-02-22 09:27:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\views\login\login.vue
-->
<template>
  <div class="login-page">
    <img src="../../assets/img/logo.png" alt="" class="logo">
    <div class="title">
      你好！欢迎登录【上海蓝十字脑科互联网医院】！
    </div>
    <van-form @submit="onSubmit">
      <label class="form-label">手机号</label>
      <van-field v-model="phoneNum" maxlength="11" placeholder="请输入手机号" />
      <div v-if="loginType == 1">
        <label class="form-label">验证码</label>
        <van-field
          v-model="verifyCode"
          maxlength="6"
          placeholder="请输入验证码"
        >
          <template #button>
            <Countdown
              :phone-num="phoneNum"
              @get-code-success="getCodeSuccess"
            />
          </template>
        </van-field>
      </div>
      <div v-if="loginType == 2">
        <label class="form-label">密码</label>
        <van-field
          v-model="password"
          type="password"
          maxlength="6"
          placeholder="请输入密码"
        >
          <template #button>
            <!-- <router-link to="/forgetNumber" class="blue-color">
              忘记密码？
            </router-link> -->
          </template>
        </van-field>
      </div>
      <!-- <div class="agreement-container">
        <van-checkbox
          v-model="checked"
          checked-color="#3A8AE5"
          class="check-box"
          icon-size="16px"
        >
          我已同意
          <router-link
            to="/linkpath"
            class="blue-color"
          >
            《蓝十字脑科互联网医院用户协议》
          </router-link>
        </van-checkbox>
        <router-link to="/register">
          <span class="rightlink">快速注册</span>
        </router-link>
      </div> -->
      <div class="agreement-container">
        <div>
          登录代表您已同意
          <router-link to="/introduce/service" class="blue-color">
            《用户协议》
          </router-link>、
          <router-link to="/introduce/privacy" class="blue-color">
            《隐私保护政策》
          </router-link>
        </div>
      </div>
      <div class="btn-box">
        <van-button
          round
          block
          native-type="submit"
          class="login-btn"
          :disabled="disabled"
        >
          登录
        </van-button>
        <!-- <van-button
          v-show="loginType == 1"
          round
          block
          plain
          class="white-btn"
          native-type="button"
          @click="loginType = 2"
        >
          密码登录
        </van-button> -->
        <van-button
          v-show="loginType == 2"
          round
          block
          plain
          native-type="button"
          class="white-btn"
          @click="loginType = 1"
        >
          验证码登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import Countdown from "../../components/countdown/Countdown"
import { userModel } from "@/api/user"
import { WX_CONST } from "../../utils/constant"
export default {
  name: "Login",
  components: {
    Countdown
  },
  data() {
    return {
      phoneNum: "",
      verifyCode: "",
      password: "",
      checked: false,
      hasGetCode: false,
      // code:'', //011gHa0w3D6efW2pdQ2w3r67rd2gHa0y
      loginType: 1, //1 验证码登录 2：密码登录
      wxtoken: ""
    }
  },
  computed: {
    disabled() {
      if (this.loginType == 1) {
        return !(this.phoneNum && this.verifyCode)
      } else {
        return !(this.phoneNum && this.password)
      }
    }
  },
  mounted() {
    const phoneNumber = this.$store.getters.phoneNum
    // console.log(window.location.href.includes("code"), "window")
    console.log(phoneNumber, "phoneNumber")
    // console.log("href", window.location.href)
    if (!window.location.href.includes("code")) {
      const url = encodeURIComponent(window.location.href)
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        WX_CONST.appid +
        "&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_base#wechat_redirect"
    } else {
      this.getToken()
    }
  },
  methods: {
    getCodeSuccess() {
      this.hasGetCode = true
    },
    getToken() {
      const reg = /code=(.*)?&/g
      const result = reg.exec(window.location.href)
      let code = ""
      // console.log(result, "reuslt+++++")
      if (result && result.length) {
        code = result[1]
      }
      userModel
        .getWxTokenByCode({ wxmpCode: code, appid: WX_CONST.appid })
        .then((res) => {
          if (res.code == 200) {
            this.wxtoken = res.data.wxtoken
          }
        })
    },
    onSubmit() {
      const reg = /code=(.*)?&/g
      const result = reg.exec(window.location.href)
      let code = ""
      console.log(result, "reuslt+++++")
      if (result && result.length) {
        code = result[1]
      }
      if (this.loginType == 1) {
        if (!this.hasGetCode) {
          return this.$toast("请先获取验证码！")
        }
        // console.log(code, "code+++++")
        let params = {
          captcha: this.verifyCode,
          phone: this.phoneNum,
          wxtoken: this.wxtoken
        }
        userModel.login(params).then((res) => {
          if (res.code == 200) {
            const { token, refreshToken, nickName, phone } = res.data
            this.$store.commit("user/setName", nickName)
            this.$store.commit("user/setPhoneNum", phone)
            this.$store.commit("user/setToken", token)
            this.$store.commit("user/setRefreshToken", refreshToken)
            // this.$router.replace("/")
            let host = window.location.protocol + "//" + window.location.host
            const url = encodeURIComponent(host + "/")
            window.location.href = url
          }
        })
      } else {
        let params = {
          password: this.password,
          phone: this.phoneNum,
          code: code
        }
        userModel.loginByPwd(params).then((res) => {
          if (res.code == 200) {
            const { token, refreshToken, nickName, phone } = res.data
            this.$store.commit("user/setName", nickName)
            this.$store.commit("user/setPhoneNum", phone)
            this.$store.commit("user/setToken", token)
            this.$store.commit("user/setRefreshToken", refreshToken)
            // this.$router.replace("/")
            let host = window.location.protocol + "//" + window.location.host
            window.location.href = host
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/styles/variable.less";
.form-label {
  display: block;
  margin-top: 40px;
  font-size: 32px;
  font-weight: 400;
  color: @dark-font-color;
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
  &::after {
    border-bottom: 0;
  }
}
.van-field {
  border-bottom: 1px solid #ddd;
}
.login-btn {
  width: 686px;
  height: 80px;
  font-size: 30px;
  color: #ffffff;
  background: #3a8ae5;
}
.white-btn {
  width: 686px;
  height: 80px;
  font-size: 30px;
  color: #333;
  background: #ffffff;
  margin-top: 24px;
}
.login-page {
  padding: 0 32px;
  .logo {
    width: 556px;
    height: 67px;
  }
  .title {
    margin-top: 24px;
    margin-bottom: 80px;
    font-size: 36px;
    font-weight: bold;
    color: @dark-font-color;
  }

  .agreement-container {
    position: relative;
    color: @middle-font-color;
    font-size: 24px;
    padding: 32px 0;
    .check-box {
      width: 80%;
    }
    .rightlink {
      font-size: 24px;
      color: #3d3d3d;
      position: absolute;
      top: 30px;
      right: 0;
    }
  }

  .btn-box {
    margin: 70px 0 24px;
    .disable {
      background: #b1d3fa;
    }
  }
}
</style>
